import { useTranslate } from "src/i18n/useTranslate"
import { MCheckbox } from "src/ui/MCheckbox/MCheckbox"

export function MarketingConsentCheckbox({
  checked,
  onChecked,
}: {
  checked: boolean
  onChecked: (checked: boolean) => void
}) {
  const { t, langKeys } = useTranslate()
  return (
    <MCheckbox
      label={t(langKeys.marketing_consent)}
      checked={checked}
      onCheck={onChecked}
      inputProps={{ name: "ConsentBox" }}
    />
  )
}
