import { useEffect, useState } from "react"
import styled from "styled-components"

import { MLink } from "src/ui/Link/MLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"
import { sleep } from "src/utils/genericUtil"

export function FormInput({
  value,
  onChange,
  requiredDelay = 1,
}: {
  value: string
  onChange: (arg: string) => void
  requiredDelay?: number // seconds
}) {
  const [required, setRequired] = useState(true)

  useEffect(() => {
    let cancel = false

    async function load() {
      await sleep(requiredDelay * 1000)
      if (cancel) return
      setRequired(false)
    }

    load()

    return () => {
      cancel = true
    }
  }, [requiredDelay])

  return (
    <InputBox>
      {/* Chrome doesn't respect the HTML5 standard 'autocomplete="off"', so
      this dummy input exists to catch spurious autocompletes */}
      <Input label="name" type="text" tabIndex={-1} />

      {/* Inspired by https://stackoverflow.com/questions/36227376/better-honeypot-implementation-form-anti-spam */}
      <Input
        label="minut-password"
        id="minut-password"
        type="password"
        tabIndex={-1}
        autoComplete="off"
        value={value}
        onChange={(value) => onChange(value)}
        required={required}
      />
      <Input
        label="minut-website-url"
        id="minut-website-url"
        type="text"
        tabIndex={-1}
        autoComplete="off"
        value={value}
        onChange={(value) => onChange(value)}
        required={required}
      />
      {!!value && (
        <StyledAlert type="error">
          There is an issue with your browser that prevents you from proceeding.
          Please contact support at{" "}
          <MLink href="mailto:support@minut.com">support@minut.com</MLink>
        </StyledAlert>
      )}
    </InputBox>
  )
}

const InputBox = styled.div`
  position: relative;
  overflow: hidden;
`

const Input = styled(MTextField)`
  position: absolute;
  left: -100vmax;
`

const StyledAlert = styled(MBanner)`
  margin: ${spacing.M} 0;
`
