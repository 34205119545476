import {
  HREF_MINUT_PRIVACY_POLICY,
  HREF_MINUT_TERMS_OF_SERVICE,
} from "src/constants/hrefs"
import { useTranslate } from "src/i18n/useTranslate"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MCheckbox } from "src/ui/MCheckbox/MCheckbox"
import { MText } from "src/ui/MText"

export function TosCheckbox({
  checked,
  onChecked,
}: {
  checked: boolean
  onChecked: (checked: boolean) => void
}) {
  const { Trans, t, langKeys } = useTranslate()

  return (
    <MCheckbox
      label={
        <MText variant="bodyS" color="secondary" textAlign="left">
          <Trans
            i18nKey={langKeys.accept_terms_of_service_web}
            components={{
              tos: (
                <ExternalLink href={HREF_MINUT_TERMS_OF_SERVICE}>
                  {t(langKeys.terms_of_service)}
                </ExternalLink>
              ),
              privacy: (
                <ExternalLink href={HREF_MINUT_PRIVACY_POLICY}>
                  {t(langKeys.privacy_policy)}
                </ExternalLink>
              ),
            }}
          />
        </MText>
      }
      checked={checked}
      onCheck={onChecked}
      inputProps={{ name: "TOS accepted" }}
    />
  )
}
